import React, { FC, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from '../assets/icons/icon-dashboard.svg';
import { ReactComponent as AssessmentsIcon } from '../assets/icons/icon-people.svg';
import { ReactComponent as FoldersIcon } from '../assets/icons/icon-folder.svg';
import { ReactComponent as TargetsIcon } from '../assets/icons/icon-position.svg';
import { ReactComponent as AdminIcon } from '../assets/icons/icon-users.svg';
import { ReactComponent as GodModeIcon } from '../assets/icons/icon-god-mode.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/icon-logout.svg';
import { ReactComponent as TeamsIcon } from '../assets/icons/icon-teams.svg';
import { Sun, Moon, Desktop } from "@phosphor-icons/react";

interface Props {
    setIsMenuOpen?: (menuOpen: boolean) => void;
    handleLogout: () => void;
    darkMode: 'light' | 'dark' | 'system';
    setDarkMode: (mode: 'light' | 'dark' | 'system') => void;
}

const NavigationLinks: FC<Props> = ({ setIsMenuOpen, handleLogout, darkMode, setDarkMode }) => {
    const [isThemeMenuOpen, setIsThemeMenuOpen] = useState(false);

    const userDetail = JSON.parse(localStorage.getItem('user') || "{}");
    const location = useLocation();

    //GOD_MODE_USER_ID
    const IS_GOD_MODE_USER: boolean = userDetail.user.isGodModeUser;
    const IS_ADVISOR: boolean = userDetail ? userDetail.user.isAdvisor : false;

    let entityMenuName = "";
    if (IS_GOD_MODE_USER) {
        entityMenuName = "God Mode";
    } else if (IS_ADVISOR) {
        entityMenuName = "Clients";
    } else if (userDetail.user.permissions.viewAnalytics){
        entityMenuName = "Analytics"
    }

    if (!userDetail.user.permissions.people && !userDetail.user.permissions.positionsTargets && !userDetail.user.permissions.folders && !userDetail.user.permissions.users && userDetail.user.permissions.viewOwnProfileOnly ) {
        return <></>;
    }

    const handleThemeToggle = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsThemeMenuOpen(!isThemeMenuOpen);
    };

    const handleThemeChange = (mode: 'light' | 'dark' | 'system') => {
        setDarkMode(mode);
        setIsThemeMenuOpen(false);
        setIsMenuOpen && setIsMenuOpen(false);
    };

    return (
        <ul className="sidebar-navigation">
            <li>
                <NavLink
                    onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                    to="/dashboard"
                    className={({ isActive }) => (isActive || location.pathname === '/' ? 'active' : '')}
                >
                    {({ isActive }) => (
                        <>
                            <DashboardIcon className={isActive || location.pathname === '/' ? 'active' : ''} />
                            <span>Dashboard</span>
                        </>
                    )}
                </NavLink>
            </li>
            {(userDetail.user.permissions.people || userDetail.user.role == "Super Admin") &&
                <li>
                    <NavLink
                        onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                        to="/people"
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        {({ isActive }) => (
                            <>
                                <AssessmentsIcon className={isActive ? 'active' : ''} />
                                <span>People</span>
                            </>
                        )}
                    </NavLink>
                </li>}
            {(userDetail.user.permissions.positionsTargets || userDetail.user.role == "Super Admin") &&
                <li>
                    <NavLink
                        onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                        to="/positions"
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        {({ isActive }) => (
                            <>
                                <TargetsIcon className={isActive ? 'active' : ''} />
                                <span>Positions</span>
                            </>
                        )}
                    </NavLink>
                </li>}
            {(userDetail.user.permissions.folders || userDetail.user.role == "Super Admin") &&
                <li>
                    <NavLink
                        onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                        to="/folders"
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        {({ isActive }) => (
                            <>
                                <FoldersIcon className={isActive ? 'active' : ''} />
                                <span>Folders</span>
                            </>
                        )}
                    </NavLink>
                </li>}

            {(userDetail.user.permissions.teams || userDetail.user.role == "Super Admin") &&
                <li>
                    <NavLink
                        onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                        to="/teams"
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        {({ isActive }) => (
                            <>
                                <TeamsIcon className={isActive ? 'active' : ''} />
                                <span>Teams</span>
                            </>
                        )}
                    </NavLink>
                </li>
            }

            <li className={`theme-menu hide-on-desktop ${isThemeMenuOpen ? 'open' : ''}`}>
                <a href="#" onClick={handleThemeToggle}>
                    {darkMode === 'dark' ? <Moon className="nav-icon" /> :
                        darkMode === 'light' ? <Sun className="nav-icon" /> :
                            <Desktop className="nav-icon" />}
                    <span>Theme</span>
                </a>
                <ul className="theme-submenu">
                    <li>
                        <button onClick={() => handleThemeChange('light')}>
                            <Sun size={16} /> Light
                        </button>
                    </li>
                    <li>
                        <button onClick={() => handleThemeChange('dark')}>
                            <Moon size={16} /> Dark
                        </button>
                    </li>
                    <li>
                        <button onClick={() => handleThemeChange('system')}>
                            <Desktop size={16} /> System
                        </button>
                    </li>
                </ul>
            </li>

            {(userDetail.user.permissions.users || userDetail.user.role == "Super Admin") &&
                <li>
                    <NavLink
                        onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                        to="/users"
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        {({ isActive }) => (
                            <>
                                <AdminIcon className={isActive ? 'active' : ''} />
                                <span>Users</span>
                            </>
                        )}
                    </NavLink>
                </li>}
            {(IS_GOD_MODE_USER || IS_ADVISOR || userDetail.user.permissions.viewAnalytics) &&
                <li>
                    <NavLink
                        onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                        to="/entities"
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        {({ isActive }) => (
                            <>
                                <GodModeIcon className={isActive ? 'active' : ''} />
                                <span>{entityMenuName}</span>
                            </>
                        )}
                    </NavLink>
                </li>
            }

            <li className="mobile-logout">
                <a href="/login" onClick={handleLogout}>
                    <LogoutIcon />
                    <span>Logout</span>
                </a>
            </li>
        </ul>
    );
}

export default NavigationLinks;
